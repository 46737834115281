import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IconButton, IconButtonProps } from "@mui/material";
import { FiberManualRecord, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import { IContentBandLayoutProps } from "./contentBandLayoutPreview";
import { ContentBandContentType, IContentBand } from "../../models";
import { PostFeedItem } from "modules/posts/models";
import { EventFeedItem } from "modules/events";
import BreakingFeaturedBanner from "modules/posts/components/banners/breakingFeaturedBanner";
import DateBlock from "modules/events/components/date-block/dateBlock";
import ReadDate from "modules/posts/components/post-feed/components/dates/ReadDate";
import useIsFirstRender from "modules/common/hooks/useIsFirstRender";
import { useSelector } from "react-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { DEFAULT_CONFIG } from "../../reducer";
import { getTranslatedDescription } from "utils/getTranslatedContent";

interface IContentBandCarouselProps {
    contentBand: IContentBand;
    items: PostFeedItem[] | EventFeedItem[];
    activeLcid: string;
    autoPlay: boolean;
    fullWidth?: boolean;
    size: "sm" | "lg";
    contentType?: ContentBandContentType;
    getLeftInfo: (item: PostFeedItem | EventFeedItem) => JSX.Element;
    getPostTypeBanner: (item: PostFeedItem) => JSX.Element;
}

export const ContentBandCarousel: React.FunctionComponent<IContentBandCarouselProps> = ({
    contentBand,
    items,
    activeLcid,
    autoPlay,
    size = "sm",
    contentType = ContentBandContentType.Post,
    fullWidth = true,
    getPostTypeBanner,
    getLeftInfo,
}) => {
    const itemCount = items.length;
    const pinnedIds = contentBand.pinnedIds;
    const [activeIdx, setActiveIdx] = useState<number>(0);
    const isPosts = contentBand.contentType === ContentBandContentType.Post;
    const isFirstRender = useIsFirstRender();
    const timeout = useRef<NodeJS.Timeout | null>(null);

    const timeoutHandler = useCallback(() => {
        setActiveIdx((prev) => {
            let next = ++prev;

            if (prev === itemCount) next = 0;

            return next;
        });
    }, [itemCount]);

    // go back to beginning of carousel if pinned items change
    useEffect(() => {
        if (isFirstRender && (pinnedIds?.length || 0) > 0) setActiveIdx(0);
    }, [isFirstRender, pinnedIds]);

    useEffect(() => {
        if (autoPlay) timeout.current = setInterval(timeoutHandler, 6000);

        return () => {
            if (timeout.current) clearInterval(timeout.current);
        };
    }, [autoPlay, itemCount, timeoutHandler]);

    const onClickDot = (idx: number) => {
        // clear timeout so 6 seconds starts again
        if (timeout.current) clearInterval(timeout.current);

        if (idx > -1 && idx < items.length) setActiveIdx(idx);

        // restart timer
        if (autoPlay) timeout.current = setInterval(timeoutHandler, 6000);
    };

    const onNext = () => {
        setActiveIdx((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    const onPrev = () => {
        setActiveIdx((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    return (
        <>
            <div className={`content-band-carousel ${fullWidth ? "full-width" : "preview"} ${size}`}>
                {items.map((item: PostFeedItem | EventFeedItem) => (
                    <ContentBandCarouselItem
                        fullWidth={fullWidth}
                        key={`${item.id}-carousel-item`}
                        size={size}
                        title={item.title}
                        summary={getTranslatedDescription(item.translatedContent, activeLcid)}
                        heroUrl={item.imageUrl}
                        carouselIdx={activeIdx}
                        leftHeroBadge={isPosts ? getPostTypeBanner(item as PostFeedItem) : undefined}
                        settings={contentBand}
                        bannerColor={isPosts ? undefined : (item as EventFeedItem).bannerColor}
                        dateBlock={isPosts ? undefined : { ...(item as EventFeedItem), showMultiBlocks: false }}
                        rightHeroBadge={isPosts ? <ReadDate hideWhenRead lastReadTime={(item as PostFeedItem).lastReadTime} /> : undefined}
                        leftInfo={getLeftInfo(item)}
                        rightInfo={
                            <BreakingFeaturedBanner activeLcid={activeLcid} isBreaking={item.isBreaking} isFeatured={item.isFeatured} />
                        }
                        contentType={contentType}
                        onNext={onNext}
                        onPrev={onPrev}
                    />
                ))}
            </div>
            <Dots containerClassName="dots" numDots={items.length} onClickDot={onClickDot} activeIdx={activeIdx} />
        </>
    );
};

interface IContentBandCarouselItemProps extends IContentBandLayoutProps {
    carouselIdx: number;
    fullWidth?: boolean;
    summary?: string;
    rightHeroBadge?: JSX.Element; // component to render top right corner of hero
    contentType?: ContentBandContentType;
    onNext?: () => void;
    onPrev?: () => void;
}

export const ContentBandCarouselItem: React.FunctionComponent<IContentBandCarouselItemProps> = ({
    carouselIdx,
    heroUrl,
    title,
    fullWidth = false,
    size,
    summary,
    bannerColor,
    leftInfo,
    rightInfo,
    dateBlock,
    leftHeroBadge,
    rightHeroBadge,
    contentType,
    onNext,
    onPrev,
}) => {
    const [isItemHovered, setIsItemHovered] = useState<boolean>(false);
    const { config } = useSelector((state: GlobalApplicationState) => state.contentBands);
    const usableConfig = config || DEFAULT_CONFIG;
    const containerRef = useRef<HTMLDivElement>(null);
    const itemHorizontalMargin = fullWidth ? 0 : 10;
    const isSm = size === "sm";

    const usableTitle = useMemo(() => (title && title.length > 55 ? title.substring(0, 52).trim() + "..." : title), [title]);

    /**
     * Use parent container width to decide how much to move items over
     * as we cycle through carousel
     * - add 2*itemHorizontalMargin to make sure margins are shifted far enough over
     */
    const getTransformBy = (): number => {
        if (!containerRef.current) return 0;

        let newWidth = containerRef.current.getBoundingClientRect().width;

        return -carouselIdx * (newWidth + 2 * itemHorizontalMargin);
    };

    const getOverlayInfo = (): JSX.Element => (
        <>
            <div className="hero-badges">
                {leftHeroBadge}
                <div style={{ marginRight: 10 }}>{rightHeroBadge}</div>
            </div>
            <div className="content-band-card-content">
                {isSm && dateBlock && <DateBlock {...dateBlock} showMultiBlocks={false} monthFormat="MMM" />}
                <div className="title-summary-container">
                    <span className="title">{usableTitle}</span>
                    {!isSm && summary && <span className="summary">{summary}</span>}
                </div>
                <div className="info">
                    {leftInfo}
                    {rightInfo && <div style={leftInfo ? {} : { marginLeft: "auto" }}>{rightInfo}</div>}
                </div>
            </div>
        </>
    );

    // helper to get the common props for the arrow buttons on lg carousel
    const getHoverArrowButtonsProps = (): IconButtonProps => ({
        sx: {
            visibility: isItemHovered ? "visible" : "hidden",
            borderRadius: "50%",
            bgcolor: "#7f7f7f",
            "&:hover": {
                bgcolor: "#7f7f7f",
            },
        },
    });

    const linearGradient =
        "linear-gradient(to bottom,rgba(255,255,255,0.50)," +
        `rgba(${usableConfig.contentBandsGradientColor.rgb.r},` +
        `${usableConfig.contentBandsGradientColor.rgb.g},` +
        `${usableConfig.contentBandsGradientColor.rgb.b}, 0.5))`;

    return (
        <div
            className={`content-band-carousel-item item-transition-${carouselIdx} ${size} ${contentType}`}
            style={{
                transform: `translateX(${getTransformBy()}px)`,
                margin: fullWidth ? "" : `0px ${itemHorizontalMargin}px`,
            }}
            ref={containerRef}
            onMouseEnter={() => setIsItemHovered(true)}
            onMouseLeave={() => setIsItemHovered(false)}
        >
            {!isSm && (
                <>
                    <div
                        className="content-band-carousel-lg"
                        style={{
                            backgroundImage: linearGradient,
                        }}
                    >
                        {getOverlayInfo()}
                    </div>
                    <div className="carousel-overlay-btns">
                        <IconButton onClick={onPrev} {...getHoverArrowButtonsProps()}>
                            <KeyboardArrowLeft htmlColor="#ffffff" sx={{ fontSize: 30 }} />
                        </IconButton>
                        <IconButton onClick={onNext} {...getHoverArrowButtonsProps()}>
                            <KeyboardArrowRight htmlColor="#ffffff" sx={{ fontSize: 30 }} />
                        </IconButton>
                    </div>
                </>
            )}
            <div
                className="hero-img"
                style={{
                    backgroundColor: heroUrl ? "rgb(221, 225, 229)" : bannerColor,
                    backgroundImage: `${isSm ? `${linearGradient},` : ""} url(${heroUrl})`,
                }}
            >
                {isSm ? getOverlayInfo() : dateBlock && <DateBlock {...dateBlock} showMultiBlocks={false} monthFormat="MMM" />}
            </div>
        </div>
    );
};

interface IDotProps {
    numDots: number;
    activeIdx: number;
    containerClassName?: string;
    onClickDot: (idx: number) => void;
}

export const Dots: React.FC<IDotProps> = ({ numDots, activeIdx, containerClassName, onClickDot }) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
            }}
            className={containerClassName}
        >
            {Array(numDots)
                .fill(0)
                .map((_, idx: number) => (
                    <IconButton
                        id={`dots-${idx}`}
                        key={`dots-${idx}`}
                        className={`dot ${activeIdx === idx ? "active" : ""}`}
                        style={{
                            color: activeIdx === idx ? "#7f7f7f" : "rgba(127, 127, 127, 0.36)",
                            padding: 0,
                        }}
                        onClick={() => onClickDot(idx)}
                    >
                        <FiberManualRecord style={{ fontSize: 17 }} />
                    </IconButton>
                ))}
        </div>
    );
};
