import React, { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton } from "@mui/material";
import { Add, ContentCopy, Delete, DragIndicator, KeyboardArrowDown, Refresh } from "@mui/icons-material";

import { ContentBandLayoutType, ContentBandStatus, IContentBand } from "../../models";
import { contentBandsSlice } from "../../reducer";
import { useLanguagesData } from "modules/common/hooks/data/useLanguages";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import LanguageBar from "modules/localization/components/languageBar";
import { ContentBandEditor } from "./contentBandEditor";
import { IContentBandSettingActionProps } from "./contentBandsEditorPage";
import { TenantSettingsTagGroup } from "modules/settings";
import { dropItemBackward, dropItemForward, useDragReorder } from "modules/common/hooks/useDragReorder";
import TranslateContentConfirmation from "modules/common/components/authoring/dialogs/translateContentConfirmation";
import { ILanguage } from "modules/localization/models";
import { cognitiveServicesApi } from "api/instances";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import { HoverAddButton } from "modules/common/components/buttons/hoverAddButton";
import { GlobalApplicationState } from "globalApplicationState";

import "../../styles/contentBandsMainEditor.sass";
import uuid from "uuid";

interface IContentBandsMainEditor extends IContentBandSettingActionProps {
    contentBands: IContentBand[];
    userTagGroups: TenantSettingsTagGroup[];
    languageInfo: useLanguagesData;
    fetchContentForBand: (band: IContentBand, idx: number) => void;
}

export const ContentBandsMainEditor: React.FunctionComponent<IContentBandsMainEditor> = ({
    contentBands,
    userTagGroups,
    fetchContentForBand,
    languageInfo,
    ...actions
}) => {
    const dispatch = useDispatch();
    const { addFinished, addIdx } = useSelector((state: GlobalApplicationState) => state.contentBands);
    const [activeIdx, setActiveIdx] = useState<number | null>(null);
    const [draggedIdx, setDraggedIdx] = useState<number | null>(null);
    const [confirmClearTranslate, setConfirmClearTranslate] = useState<boolean>(false);
    const [confirmMultipleLanguagesTranslate, setConfirmMultipleLanguagesTranslate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // keep track of the language user hit "x" on
    const [confirmRemoveLanguage, setConfirmRemoveLanguage] = useState<string>();
    const isDragging = draggedIdx !== null;
    const dropZoneClassName = useRef<string>(uuid());

    // enable when there is more than one language
    // and there is one language with text
    const isTranslateEnabled = useMemo(() => {
        let result =
            contentBands.length > 0 &&
            contentBands[0].headers !== undefined &&
            // languages are added globally i.e. all content bands have the same ones so here we just check the first content band
            Object.keys(contentBands[0].headers).length > 1 &&
            // see if any content bands have header with at least 1 character
            contentBands.some((x: IContentBand) => {
                let contentBandHasFilledHeader: boolean = false;

                if (x.headers) {
                    let lcids = Object.keys(x.headers);

                    for (let i = 0; i < lcids.length; i++) {
                        let key = lcids[i];

                        if (x.headers![key].header !== undefined && x.headers![key].header.length > 0) contentBandHasFilledHeader = true;

                        if (contentBandHasFilledHeader) break;
                    }
                }

                return contentBandHasFilledHeader;
            });

        return result;
    }, [contentBands]);

    const { selectedLanguages, activeLcid, defaultLcid, onSelectLanguage, onRemoveLanguage, availableLanguages } = languageInfo;

    // scroll to content band when it is opened
    useEffect(() => {
        const scrollToContentBand = (idx: number) => {
            let contentBandOffset = document.getElementById(`cb-${idx}`);
            contentBandOffset?.scrollIntoView();
        };

        if (activeIdx !== null) scrollToContentBand(activeIdx);
    }, [activeIdx]);

    // when append content band is finished,
    // fetch the content for latest content band
    useEffect(() => {
        if (addFinished) {
            const idx = addIdx || contentBands.length - 1;
            fetchContentForBand(contentBands[idx], idx);
            dispatch({ type: contentBandsSlice.actions.SET_ADD_FINISHED, payload: { addFinished: false, addIdx: undefined } });
        }
    }, [addFinished, addIdx, contentBands, dispatch, fetchContentForBand]);

    const onDrop = (_: string, droppedIdx: number) => {
        // copy to use after setting null
        const draggedIdxCopy = draggedIdx;

        setDraggedIdx(null);

        if (draggedIdxCopy !== null && draggedIdxCopy > -1) {
            let dragged = { ...contentBands[draggedIdxCopy] };

            if (draggedIdxCopy > -1 && droppedIdx > -1 && dragged) {
                // dont do anything if band is in its original spot
                if (droppedIdx === draggedIdx || draggedIdxCopy === null) return;

                let newItems =
                    draggedIdxCopy < dropIdx
                        ? dropItemForward(contentBands, draggedIdxCopy, dropIdx)
                        : dropItemBackward(contentBands, draggedIdxCopy, dropIdx);

                let newBands = newItems.map((cb: IContentBand, idx: number) => ({
                    ...cb,
                    order: idx,
                }));

                dispatch({ type: contentBandsSlice.actions.SET_CONTENT_BANDS, payload: { contentBands: newBands, reordered: true } });
            }
        }
    };

    const { mousePos, dropIdx } = useDragReorder<string>({
        enabled: true,
        draggedIdx: draggedIdx,
        items: contentBands.map((b: IContentBand) => ({
            id: b.id,
        })),
        dropZoneClassName: dropZoneClassName.current,
        onDrop,
    });

    const onAddContentBandClick = () => {
        let idx = contentBands.length;
        dispatch({ type: contentBandsSlice.actions.APPEND_DEFUALT_CONTENT_BAND, payload: { defaultLcid } });

        setActiveIdx(idx);
    };

    const onInsertContentBandClick = (idx: number) => {
        dispatch({ type: contentBandsSlice.actions.INSERT_DEFAULT_CONTENT_BAND, payload: { idx: ++idx, defaultLcid } });
        setActiveIdx(idx);
    };

    const getAddContentBandButton = (): JSX.Element => {
        return (
            <Button
                id="add-new-content-band"
                variant="contained"
                color="primary"
                onClick={onAddContentBandClick}
                startIcon={<Add />}
                sx={{ marginTop: "30px!important" }}
            >
                CONTENT BAND
            </Button>
        );
    };

    // add new language to each content bands headers property
    const onAddLanguage = (lang: Record<string, string>) => {
        const needToAdd =
            !selectedLanguages?.some((selected: ILanguage) => selected.lcid === lang.key) ||
            contentBands.some((band: IContentBand) => !Boolean(band.headers) || !Boolean(band.headers![lang.key]));

        // set new language as active
        onSelectLanguage(lang.key);

        if (needToAdd) dispatch({ type: contentBandsSlice.actions.ADD_LANGUAGE, payload: lang });
    };

    const translationHasContent = (lcid: string): boolean =>
        contentBands.some((band: IContentBand) => band.headers && band.headers[lcid].header);

    const onRemoveLanguageClick = (lang: Record<string, string>) => {
        if (translationHasContent(lang.key)) setConfirmRemoveLanguage(lang.key);
        else onConfirmRemoveLanguage(lang.key);
    };

    const onConfirmRemoveLanguage = (lcid?: string) => {
        lcid = lcid || confirmRemoveLanguage;

        if (lcid) {
            onRemoveLanguage(lcid);

            dispatch({ type: contentBandsSlice.actions.REMOVE_LANGUAGE, payload: lcid });
        }

        setConfirmRemoveLanguage(undefined);
    };

    // check if we should confirm text will be cleared
    const onTranslate = async () => {
        const shouldConfirm = contentBands.some((band: IContentBand) => band.headers && band.headers[activeLcid].header);

        if (shouldConfirm) setConfirmClearTranslate(true);
        else await onConfirmClearTranslate();
    };

    // check if we should confirm which language to translate from
    const onConfirmClearTranslate = async () => {
        setConfirmClearTranslate(false);
        const shouldConfirm = selectedLanguages && selectedLanguages.length > 2;

        if (shouldConfirm) {
            setConfirmMultipleLanguagesTranslate(true);
        } else {
            await onConfirmTranslateFrom(selectedLanguages?.find((lang: ILanguage) => lang.lcid !== activeLcid)?.lcid || "");
        }
    };

    // translate each content band's headers
    const onConfirmTranslateFrom = async (fromLcid: string) => {
        setConfirmMultipleLanguagesTranslate(false);

        setIsLoading(true);

        for (let i = 0; i < contentBands.length; i++) {
            let band = contentBands[i];
            if (!band.headers) continue;

            let translateReq = {
                text: band.headers[fromLcid].header,
                fromLcid,
                toLcid: activeLcid,
            };

            let {
                data: { Translations },
            } = await cognitiveServicesApi.translateText(translateReq);
            actions.onChangeHeaderText(Translations[0].text, activeLcid, i);
        }

        setIsLoading(false);
    };

    const onClickBandArea = (idx: number | null) => {
        setActiveIdx(idx);
    };

    const onDrag = (idx: number) => {
        document.body.style.cursor = "move";
        setActiveIdx(null);
        setDraggedIdx(idx);
    };

    const onCopy = (idx: number) => {
        const newIdx = idx + 1;

        const newBand: IContentBand = {
            ...contentBands[idx],
            id: "",
            order: newIdx,
            status: ContentBandStatus.Draft,
            publishedDateTimeUtc: null,
        };

        dispatch({ type: contentBandsSlice.actions.INSERT_CONTENT_BAND, payload: { idx: newIdx, contentBand: newBand } });
        setActiveIdx(newIdx);
    };

    const onDelete = (idx: number) => {
        dispatch({ type: contentBandsSlice.actions.DELETE_CONTENT_BAND, payload: { idx } });
        if (idx === activeIdx) setActiveIdx(null);
    };

    const getAvailableLanguagesToTranslateFrom = (): ILanguage[] => {
        return (
            selectedLanguages?.filter((lang: ILanguage) => {
                let keep = lang.lcid !== activeLcid;

                keep =
                    keep && contentBands.every((cb: IContentBand) => cb.headers && cb.headers[lang.lcid] && cb.headers[lang.lcid].header);

                return keep;
            }) || []
        );
    };

    return (
        <div id="content-bands-main-editor">
            <LoadingOverlay show={isLoading} />
            {contentBands.length > 0 ? (
                <>
                    <LanguageBar
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        endIcon={<KeyboardArrowDown />}
                        selectedLanguages={selectedLanguages || []}
                        onAddLanguage={onAddLanguage}
                        onRemoveLanguage={onRemoveLanguageClick}
                        onSelectLanguage={onAddLanguage}
                        onTranslate={onTranslate}
                        hide={availableLanguages?.length === 1}
                        defaultLcid={defaultLcid}
                        activeLcid={activeLcid}
                        anyAvailableTranslationsCheck={!isTranslateEnabled}
                        languages={availableLanguages?.map((lang) => ({ key: lang.lcid, value: lang.language })) || []}
                    />
                    {isDragging && (
                        <div
                            id={`cb-${draggedIdx}`}
                            className={`content-band-editor-container floating`}
                            style={{
                                border: isDragging ? "solid 1px #dde1e5" : "none",
                                left: mousePos.x,
                                top: mousePos.y,
                                padding: isDragging ? "10px" : "20px 27px 0px",
                                width: "100%",
                            }}
                        >
                            <div className="actions">
                                <IconButton id={`cb-drag-${draggedIdx}`}>
                                    <DragIndicator id={`cb-drag-icon-${draggedIdx}`} htmlColor="#3b78ab" />
                                </IconButton>
                                {isDragging ? (
                                    <label>
                                        {(contentBands[draggedIdx].headers && contentBands[draggedIdx].headers![activeLcid].header) || (
                                            <span style={{ color: "#999999" }}>(No header text)</span>
                                        )}
                                    </label>
                                ) : (
                                    <>
                                        <IconButton id={`cb-copy-${draggedIdx}`}>
                                            <ContentCopy id={`cb-copy-icon-${draggedIdx}`} htmlColor="#3b78ab" />
                                        </IconButton>
                                        <IconButton id={`cb-trash-${draggedIdx}`}>
                                            <Delete id={`cb-trash-icon-${draggedIdx}`} htmlColor="#3b78ab" />
                                        </IconButton>
                                        <IconButton id={`cb-refresh-${draggedIdx}`} sx={{ marginLeft: "auto" }}>
                                            <Refresh id={`cb-refresh-icon-${draggedIdx}`} color="primary" />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={`drop-zone ${dropZoneClassName.current} ${isDragging && dropIdx === 0 ? "" : "hidden"}`} />
                    {contentBands.map((band: IContentBand, idx: number) => {
                        const isCarousel = band.layoutType === ContentBandLayoutType.Carousel;

                        return (
                            <React.Fragment key={`cb-${idx}`}>
                                {draggedIdx !== idx && (
                                    <>
                                        <ContentBandEditor
                                            idx={idx}
                                            setActiveBand={onClickBandArea}
                                            isActive={activeIdx === null ? null : idx === activeIdx}
                                            activeLcid={activeLcid}
                                            userTagGroups={userTagGroups}
                                            defaultLcid={defaultLcid}
                                            contentBand={band}
                                            draggedIdx={draggedIdx}
                                            mousePos={mousePos}
                                            onDrag={onDrag}
                                            onCopy={onCopy}
                                            onRemove={onDelete}
                                            fetchContentForBand={fetchContentForBand}
                                            {...actions}
                                        />
                                        <div
                                            className={`drop-zone ${dropZoneClassName.current} ${
                                                isDragging && dropIdx === idx + 1 ? "" : "hidden"
                                            }`}
                                        />
                                        {idx !== contentBands.length - 1 && draggedIdx === null && (
                                            <HoverAddButton
                                                containerStyle={isCarousel ? { marginTop: 20 } : undefined}
                                                onClick={() => onInsertContentBandClick(idx)}
                                            />
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {getAddContentBandButton()}
                    <ConfirmDialog
                        title="You'll lose your translated content"
                        confirmLabel="KEEP"
                        denyLabel="REMOVE"
                        open={Boolean(confirmRemoveLanguage)}
                        onClose={() => setConfirmRemoveLanguage(undefined)}
                        onConfirm={() => setConfirmRemoveLanguage(undefined)} // KEEP
                        onDeny={() => onConfirmRemoveLanguage()}
                    >
                        <div style={{ width: 400 }}>
                            You have content on this language. Removing the language will clear the translated work you've done.
                            <br />
                            <br />
                            Do you want to continue?
                        </div>
                    </ConfirmDialog>
                    <ConfirmDialog
                        title="Translate Content"
                        confirmLabel="TRANSLATE"
                        denyLabel="CANCEL"
                        open={confirmClearTranslate}
                        onClose={() => setConfirmClearTranslate(false)}
                        onDeny={() => setConfirmClearTranslate(false)}
                        onConfirm={onConfirmClearTranslate}
                    >
                        <div style={{ width: 400 }}>
                            The auto-translate tool will clear all existing text and translations you've entered on this language.
                            <br />
                            <br />
                            Do you want to continue?
                        </div>
                    </ConfirmDialog>
                    <TranslateContentConfirmation
                        show={confirmMultipleLanguagesTranslate}
                        currentLanguagesWithText={getAvailableLanguagesToTranslateFrom()}
                        onConfirm={onConfirmTranslateFrom}
                        onCancel={() => setConfirmMultipleLanguagesTranslate(false)}
                    />
                </>
            ) : (
                <div className="empty-state">
                    <img src="/images/mobileHomeScreen.png" alt="Mobile home screen" width="150" />
                    <span className="info-text">
                        Start customizing the news, stories or events that appear on your mobile app home screen!
                    </span>
                    {getAddContentBandButton()}
                </div>
            )}
        </div>
    );
};
