import { Action, createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
    ContentBandContentType,
    ContentBandLayoutType,
    ContentBandStatus,
    IContentBand,
    IContentBandConfig,
    IContentBandsState,
    ReadStatus,
} from "./models";
import { contentBandApi } from "api/instances";
import { ImageScale } from "modules/posts/models";
import { SortStyle } from "utils/managementUtils";
import { IColorOption } from "modules/homeScreens/models";

export interface IIdxPayload {
    idx: number;
}

export interface ISetFetchIdxAction extends Action {
    payload: IIdxPayload;
}

export interface IDeleteContentBandAction extends Action {
    payload: IIdxPayload;
}

export interface ISetContentBandsPayload {
    contentBands: IContentBand[];
    reordered: boolean;
}

export interface ISetContentBandsAction extends Action {
    payload: ISetContentBandsPayload;
}

export interface IEditContentBandsActionPayload {
    idx: number;
    values: IContentBand;
}

export interface IEditContentBandsAction extends Action {
    payload: IEditContentBandsActionPayload;
}

export interface IAddDefaultContentBandsActionPayload {
    defaultLcid: string;
}

export interface IAddDefaultContentBandsAction extends Action {
    payload: IAddDefaultContentBandsActionPayload;
}

export interface IInsertDefaultContentBandActionPayload extends IAddDefaultContentBandsActionPayload {
    idx: number;
}
export interface IInsertDefaultContentBandAction extends Action {
    payload: IInsertDefaultContentBandActionPayload;
}

export interface IInsertContentBandActionPayload extends IInsertDefaultContentBandActionPayload {
    contentBand: IContentBand;
}

export interface IInsertContentBandAction extends Action {
    payload: IInsertContentBandActionPayload;
}

export interface IAddLanguageAction extends Action {
    payload: Record<string, string>;
}

export interface IRemoveLanguageAction extends Action {
    payload: string;
}

export interface ISetSuccessMessageAction extends Action {
    payload: string;
}

export interface IEditLanguagePayload {
    lcid: string;
    header: string;
    idx: number;
}

export interface IEditLanguageAction extends Action {
    payload: IEditLanguagePayload;
}

export interface ISetHeaderTextColorAction extends Action {
    payload: IColorOption;
}

export interface ISetGradientColorAction extends Action {
    payload: IColorOption;
}

export interface ISetBooleanAction extends Action {
    payload: boolean;
}

export interface ISetConfigAction extends Action {
    payload: IContentBandConfig;
}

export interface IPublishContentBandConfigPayload {
    id: string;
    config: IContentBandConfig;
}

export interface ISetAddFinishedPayload {
    addFinished: boolean;
    addIdx?: number;
}

export interface ISetAddFinishedAction extends Action {
    payload: ISetAddFinishedPayload;
}

export const DEFAULT_COLOR_OPTION: IColorOption = {
    hexCode: "#333333",
    rgb: {
        r: 51,
        g: 51,
        b: 51,
    },
};

export const DEFAULT_CONFIG: IContentBandConfig = {
    id: "",
    parentId: null,
    contentBandsGradientColor: DEFAULT_COLOR_OPTION,
    contentBandsHeaderTextColor: DEFAULT_COLOR_OPTION,
    status: ContentBandStatus.Draft,
};

const DEFAULT_CONTENT_BAND: IContentBand = {
    id: "",
    parentId: null,
    contentType: ContentBandContentType.Post,
    layoutType: ContentBandLayoutType.Card,
    seeMoreEnabled: false,
    displayFullTitles: false,
    sortBy: SortStyle.publishDesc,
    contentCount: 10,
    pinnedIds: [],
    lockToReadStatus: ReadStatus.Both,
    lockToTopicIds: [],
    lockToTypes: [],
    ignoreSubscriptions: false,
    autoPlayCarousel: false,
    status: ContentBandStatus.Draft,
    publishedDateTimeUtc: null,
    createdById: "",
    createdDateTimeUtc: null,
    events: [],
    posts: [],
    order: -1,
};

const DEFAULT_STATE: IContentBandsState = {
    contentBands: [],
    publishedContentBands: null,
    fetched: false,
    pristineContentBands: [],
    publishCount: 0,
    fetchIdx: -1,
    addFinished: false,
    reordered: false,
    isLoadingContentBandConfig: false,
    isLoadingContentBands: false,
    isLoadingContent: false,
    isLoadingPublishedContentBands: false,
    isPublishingContentBandConfig: false,
    isPublishingContentBands: false,
    isSavingContentBands: false,
    isSavingContentBandConfig: false,
};

// get a default content band using tenant default lcid
const getDefaultContentBand = (defaultLcid: string, order: number): IContentBand => {
    return {
        ...DEFAULT_CONTENT_BAND,
        headers: {
            [defaultLcid]: {
                header: "",
            },
        },
        order,
    };
};

export interface ISaveContentBandsThunkPayload {
    contentBands: IContentBand[];
    includeContent: boolean;
    successMessage: string;
}

export const fetchContentBandsAsync = createAsyncThunk("FETCH_CONTENT_BANDS", async (includeContent: boolean, thunkApi) => {
    try {
        const { data } = await contentBandApi.getContentBands(ImageScale.Mobile, { statuses: [ContentBandStatus.Draft], includeContent });
        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_PRISTINE_BANDS, payload: { contentBands: data.contentBands } });

        return data;
    } catch (err) {
        throw err;
    }
});

export const fetchPublishedBandsAsync = createAsyncThunk("FETCH_PUBLISHED_BANDS", async (includeContent: boolean = false, thunkApi) => {
    try {
        const { data } = await contentBandApi.getContentBands(ImageScale.Mobile, {
            statuses: [ContentBandStatus.Published],
            includeContent,
        });
        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_PUBLISHED_BANDS, payload: { contentBands: data.contentBands } });

        return data;
    } catch (err) {
        throw err;
    }
});

export const saveContentBandsAsync = createAsyncThunk("SAVE_CONTENT_BANDS", async (payload: ISaveContentBandsThunkPayload, thunkApi) => {
    try {
        await contentBandApi.saveContentBands(payload.contentBands);

        // re-fetch content bands
        thunkApi.dispatch(fetchContentBandsAsync(true));
        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_SUCCESS_MESSAGE, payload: payload.successMessage });
    } catch (err) {
        throw err;
    }
});

export interface IFetchContentForBandThunkPayload {
    idx: number;
    band: IContentBand;
}

export const fetchContentForBandAsync = createAsyncThunk(
    "FETCH_CONTENT_FOR_BAND",
    async (payload: IFetchContentForBandThunkPayload, thunkApi) => {
        try {
            thunkApi.dispatch({ type: contentBandsSlice.actions.SET_FETCH_IDX, payload });

            const { data } = await contentBandApi.getContentBandContent(payload.band, ImageScale.Mobile, 1);

            const newBand = {
                ...payload.band,
                posts: data.posts || [],
                events: data.events || [],
            };

            thunkApi.dispatch({
                type: contentBandsSlice.actions.EDIT_CONTENT_BAND,
                payload: {
                    idx: payload.idx,
                    values: newBand,
                },
            });
        } catch (err) {
            throw err;
        }
    }
);

export const publishContentBandsAsync = createAsyncThunk(
    "PUBLISH_CONTENT_BANDS",
    async (payload: ISaveContentBandsThunkPayload, thunkApi) => {
        try {
            await contentBandApi.publishContentBands(payload.contentBands);

            // re-fetch draft and published bands
            thunkApi.dispatch(fetchContentBandsAsync(false));
            thunkApi.dispatch(fetchPublishedBandsAsync(false));
            thunkApi.dispatch({ type: contentBandsSlice.actions.SET_SUCCESS_MESSAGE, payload: payload.successMessage });
        } catch (err) {
            throw err;
        }
    }
);

export const fetchContentBandConfigAsync = createAsyncThunk("FETCH_CONTENT_BAND_CONFIG", async (_, thunkApi) => {
    try {
        const { data } = await contentBandApi.getContentBandConfig(ContentBandStatus.Draft);

        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_CONFIG, payload: data });
        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_PRISTINE_CONFIG, payload: data });
    } catch (err) {
        throw err;
    }
});

export const saveContentBandConfigAsync = createAsyncThunk("SAVE_CONTENT_BAND_CONFIG", async (payload: IContentBandConfig, thunkApi) => {
    try {
        await contentBandApi.saveContentBandConfig(payload);

        // set pristine copy
        thunkApi.dispatch({ type: contentBandsSlice.actions.SET_PRISTINE_CONFIG, payload });
    } catch (err) {
        throw err;
    }
});

export const publishContentBandConfigAsync = createAsyncThunk(
    "PUBLISH_CONTENT_BAND_CONFIG",
    async (payload: IContentBandConfig, thunkApi) => {
        try {
            await contentBandApi.publishContentBandConfig(payload);

            // set pristine copy
            thunkApi.dispatch({ type: contentBandsSlice.actions.SET_PRISTINE_CONFIG, payload: payload });
        } catch (err) {
            throw err;
        }
    }
);

const reorderMap = (cb: IContentBand, idx: number) => ({ ...cb, order: idx });
const sortBands = (a: IContentBand, b: IContentBand) => a.order - b.order

export const contentBandsSlice = createSlice({
    name: "contentBands",
    initialState: DEFAULT_STATE,
    reducers: {
        SET_CONTENT_BANDS: (state: IContentBandsState, action: ISetContentBandsAction) => {
            // setting state directly here is ok because this gets passed to createReducer
            // https://redux-toolkit.js.org/api/createslice#reducers
            state.contentBands = action.payload.contentBands;
            state.reordered = action.payload.reordered;
        },
        SET_PUBLISHED_BANDS: (state: IContentBandsState, action: ISetContentBandsAction) => {
            state.publishedContentBands = action.payload.contentBands;
        },
        SET_PRISTINE_BANDS: (state: IContentBandsState, action: ISetContentBandsAction) => {
            state.pristineContentBands = action.payload.contentBands;
        },
        APPEND_DEFUALT_CONTENT_BAND: (state: IContentBandsState, action: IAddDefaultContentBandsAction) => {
            state.contentBands = [...state.contentBands, getDefaultContentBand(action.payload.defaultLcid, state.contentBands.length)];
            state.addFinished = true;
        },
        INSERT_DEFAULT_CONTENT_BAND: (state: IContentBandsState, action: IInsertDefaultContentBandAction) => {
            // insert item at index
            let newBands = [...state.contentBands];
            newBands.splice(action.payload.idx, 0, getDefaultContentBand(action.payload.defaultLcid, action.payload.idx));

            state.contentBands = newBands.map(reorderMap).sort(sortBands);
            state.addFinished = true;
            state.addIdx = action.payload.idx;
            state.reordered = true;
        },
        INSERT_CONTENT_BAND: (state: IContentBandsState, action: IInsertContentBandAction) => {
            // insert item at index
            let newBands = [...state.contentBands];
            newBands.splice(action.payload.idx, 0, action.payload.contentBand);

            state.reordered = true;
            state.contentBands = newBands.map(reorderMap).sort(sortBands);
        },
        EDIT_CONTENT_BAND: (state: IContentBandsState, action: IEditContentBandsAction) => {
            let newBands = state.contentBands.map((contentBand: IContentBand, index: number) => {
                let newBand = { ...contentBand };

                if (index === action.payload.idx)
                    newBand = {
                        ...newBand,
                        ...action.payload.values,
                    };

                return newBand;
            });

            state.contentBands = newBands;
        },
        DELETE_CONTENT_BAND: (state: IContentBandsState, action: IDeleteContentBandAction) => {
            let newBands = [...state.contentBands];
            newBands.splice(action.payload.idx, 1);

            state.reordered = true;
            state.contentBands = newBands.map(reorderMap).sort(sortBands);;
        },
        ADD_LANGUAGE: (state: IContentBandsState, action: IAddLanguageAction) => {
            const lang = action.payload;

            let newBands = state.contentBands.map((band: IContentBand) => ({
                ...band,
                headers: {
                    ...band.headers,
                    [lang.key]: {
                        header: band.headers ? band.headers[lang.key]?.header : "",
                    },
                },
            }));

            state.contentBands = newBands;
        },
        REMOVE_LANGUAGE: (state: IContentBandsState, action: IRemoveLanguageAction) => {
            const lcid = action.payload;

            let newBands = state.contentBands.map((band: IContentBand) => {
                const newBand = { ...band };

                if (newBand.headers) {
                    const { [lcid!]: removed, ...withOutLang } = newBand.headers;

                    return {
                        ...newBand,
                        headers: {
                            ...withOutLang,
                        },
                    };
                }

                return newBand;
            });

            state.contentBands = newBands;
        },
        EDIT_LANGUAGE: (state: IContentBandsState, action: IEditLanguageAction) => {
            const { lcid, header, idx } = action.payload;

            let newBands = state.contentBands.map((band: IContentBand, index: number) => {
                const newBand = { ...band };

                if (idx === index && newBand.headers) {
                    if (Object.keys(newBand.headers).includes(lcid))
                        newBand.headers[lcid] = {
                            header,
                        };
                }

                return newBand;
            });

            state.contentBands = newBands;
        },
        SET_SUCCESS_MESSAGE: (state: IContentBandsState, action: ISetSuccessMessageAction) => {
            state.successMessage = action.payload;
        },
        SET_FETCH_IDX: (state: IContentBandsState, action: ISetFetchIdxAction) => {
            state.fetchIdx = action.payload.idx;
        },
        SET_HEADER_TEXT_COLOR: (state: IContentBandsState, action: ISetHeaderTextColorAction) => {
            state.config = state.config === undefined ? DEFAULT_CONFIG : { ...state.config, contentBandsHeaderTextColor: action.payload };
        },
        SET_GRADIENT_COLOR: (state: IContentBandsState, action: ISetGradientColorAction) => {
            state.config = state.config === undefined ? DEFAULT_CONFIG : { ...state.config, contentBandsGradientColor: action.payload };
        },
        SET_ADD_FINISHED: (state: IContentBandsState, action: ISetAddFinishedAction) => {
            state.addFinished = action.payload.addFinished;
            state.addIdx = action.payload.addIdx;
        },
        SET_REORDERED: (state: IContentBandsState, action: ISetBooleanAction) => {
            state.reordered = action.payload;
        },
        SET_CONFIG: (state: IContentBandsState, action: ISetConfigAction) => {
            state.config = action.payload;
        },
        SET_PRISTINE_CONFIG: (state: IContentBandsState, action: ISetConfigAction) => {
            state.pristineConfig = action.payload;
        },
        CLEAR_BANDS: (state: IContentBandsState) => {
            state.contentBands = [];
            state.publishedContentBands = null;
            state.fetched = false;
            state.publishCount = 0;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContentBandsAsync.pending, (state: IContentBandsState) => {
                state.isLoadingContentBands = true;
            })
            .addCase(fetchContentBandsAsync.fulfilled, (state: IContentBandsState, action) => {
                state.isLoadingContentBands = false;
                state.fetched = true;
                state.publishCount = action.payload.publishCount;
                state.contentBands = action.payload.contentBands;
            })
            .addCase(fetchContentBandsAsync.rejected, (state: IContentBandsState) => {
                state.isLoadingContentBands = false;
            })
            .addCase(saveContentBandsAsync.pending, (state: IContentBandsState) => {
                state.isSavingContentBands = true;
            })
            .addCase(saveContentBandsAsync.fulfilled, (state) => {
                state.isSavingContentBands = false;
            })
            .addCase(saveContentBandsAsync.rejected, (state: IContentBandsState) => {
                state.isSavingContentBands = false;
            })
            .addCase(fetchContentForBandAsync.pending, (state: IContentBandsState) => {
                state.isLoadingContent = true;
            })
            .addCase(fetchContentForBandAsync.fulfilled, (state: IContentBandsState) => {
                state.isLoadingContent = false;
                state.fetchIdx = null;
                state.reordered = false;
            })
            .addCase(fetchContentForBandAsync.rejected, (state: IContentBandsState) => {
                state.isLoadingContent = false;
                state.fetchIdx = null;
            })
            .addCase(publishContentBandsAsync.pending, (state: IContentBandsState) => {
                state.isPublishingContentBands = true;
            })
            .addCase(publishContentBandsAsync.fulfilled, (state: IContentBandsState) => {
                state.isPublishingContentBands = false;
            })
            .addCase(publishContentBandsAsync.rejected, (state: IContentBandsState) => {
                state.isPublishingContentBands = false;
            })
            .addCase(fetchPublishedBandsAsync.pending, (state: IContentBandsState) => {
                state.isLoadingPublishedContentBands = true;
            })
            .addCase(fetchPublishedBandsAsync.fulfilled, (state: IContentBandsState) => {
                state.isLoadingPublishedContentBands = false;
            })
            .addCase(fetchPublishedBandsAsync.rejected, (state: IContentBandsState) => {
                state.isLoadingPublishedContentBands = false;
            })
            .addCase(fetchContentBandConfigAsync.pending, (state: IContentBandsState) => {
                state.isLoadingContentBandConfig = true;
            })
            .addCase(fetchContentBandConfigAsync.fulfilled, (state: IContentBandsState) => {
                state.isLoadingContentBandConfig = false;
            })
            .addCase(fetchContentBandConfigAsync.rejected, (state: IContentBandsState) => {
                state.isLoadingContentBandConfig = false;
            })
            .addCase(saveContentBandConfigAsync.pending, (state: IContentBandsState) => {
                state.isSavingContentBandConfig = true;
            })
            .addCase(saveContentBandConfigAsync.fulfilled, (state: IContentBandsState) => {
                state.isSavingContentBandConfig = false;
            })
            .addCase(saveContentBandConfigAsync.rejected, (state: IContentBandsState) => {
                state.isSavingContentBandConfig = false;
            })
            .addCase(publishContentBandConfigAsync.pending, (state: IContentBandsState) => {
                state.isPublishingContentBandConfig = true;
            })
            .addCase(publishContentBandConfigAsync.fulfilled, (state: IContentBandsState) => {
                state.isPublishingContentBandConfig = false;
            })
            .addCase(publishContentBandConfigAsync.rejected, (state: IContentBandsState) => {
                state.isPublishingContentBandConfig = false;
            });
    },
});
